import { logo, about } from "../assets/images/index";
import { Elephant } from "../assets/images/svg";
const wollyConfig = {
  name: "wolly",
  apiUrl: "https://woolly-lab-api.stage.eugeniuses.com/api/",
  image: logo,
  modelId: 4,
  title: "Woolly Lab - Customize Your Wallet",
  description: "wolly description",
  shopifyStoreName: "shopify.staging.woollymade.com",
  shopifyAccessToken: "64b5d18563a2c60356a7c4e1c5686498",
  shopifyProductHalfWallet: "7860061241571",
  shopifyProductKeychain: "7860045119715",
  shopifyProductFullWallet: "7859640729827",
  shopifyProductStickOnWallet: "7860060324067",
  shopifyProductHalfWalletAlteryx: "",
  headerContent: `HANDCRAFTED <Image className='img cursor-pointer' draggable='false'  src='${about}' /> PORTLAND OR, USA`,
  headerColor: "wolly-header-color",
  btnClasses: "",
  mainStepLogo: <Elephant />,
  footerClasses: "footer-icon",
  dotClasses: "",
  doneBtnClass: "",
  userName: "",
  password: "",
  LeaveLink: "/",
  sendgridApiKey:"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",
  apiKey: "",
  sheetId: "",
};

export default wollyConfig;
