import React from "react";
import { Home } from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AlteryxLogin from "./pages/AlteryxLogin";
import PrivateRoute from "./component/privateRoute";

const App = ({ config }) => {
  const url = window.location.href;
  const productUrl = url.substring(url.lastIndexOf("/") + 1);
  config.default.modelId = Number(productUrl) <= 4 && productUrl !== "" && process.env.REACT_APP_NODE_ENV === "staging" ? Number(productUrl) : productUrl !== '' ? productUrl : 4
  return (
    <>
      {config.default.name === "alteryx" ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute config={config} />}>
              <Route path="/" element={<Home config={config} />} />
              <Route path="/:id" element={<Home config={config} />} />
            </Route>
            <Route path="/login" element={<AlteryxLogin config={config} />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home config={config} />} />
            <Route path="/:id" element={<Home config={config} />} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
};

export default App;
